import {observable, action} from "mobx"
import api from "../../global/actions/api"
import GlobalStore from "../../global/store/GlobalStore"
import RegistersCacheStore from "../../global/store/RegistersCacheStore"

class MicroelectronicFormStore {
	@observable dropedFiles0 = []
	@observable dropedFiles1 = []
	@observable dropedFiles2 = []
	@observable dropedFiles3 = []
	@observable dropedFilesOther = []
	@observable attachmentTemplates = []

	formRef = null

	defaultFormValues = {}

	@action isSameFormValues() {
		return JSON.stringify(this.defaultFormValues) === JSON.stringify(this.formRef.values())
	}

	@action deleteLoadedAttachemnt(id, dropzoneIndex, attachmentIndex) {
		let data = {
			_id: id,
			active: false
		}
		api
			.saveAttachment(data)
			.call()
			.then(() => {
				this[`dropedFiles${dropzoneIndex}`].splice(attachmentIndex, 1)
			})
	}

	@action resetDropzones() {
		this.dropedFiles0 = []
		this.dropedFiles1 = []
		this.dropedFiles2 = []
		this.dropedFiles3 = []
		this.dropedFilesOther = []
	}

	generateDataAndSave(form, version, callback) {
		let droppedFiles = [
			...this.dropedFiles0,
			...this.dropedFiles1,
			...this.dropedFiles2,
			...this.dropedFiles3,
			...this.dropedFilesOther
		]
		if (isSafe(droppedFiles) && droppedFiles.length) {
			let req = []
			let files = droppedFiles
			var saveFunction = this.save
			// const rel_object_id = response._id
			let binaryDataArr = []
			let getdata = function(index) {
				let data = files[index]
				let binaryData
				var reader = new FileReader()
				reader.readAsBinaryString(data)
				reader.onloadend = function() {
					binaryData = reader.result
					binaryDataArr.push(binaryData)
					req.push({
						attachment_id: isNotEmpty(data._id) ? data._id : null,
						_ref: false,
						active: true,
						content_type: isNotEmpty(data.type) ? data.type : "application/octet-stream",
						// content: binaryData.split(",")[1], ???
						content: btoa(binaryData),
						type: data.databaseType,
						file_name: data.name,
						size: data.size
					})
					if (files.length > index + 1) {
						getdata(index + 1)
					} else {
						saveFunction(form, req, version, callback)
					}
				}
			}
			getdata(0)
		} else {
			this.save(form, [], version, callback)
		}
	}

	@action save(form, dropzoneData, version, callback) {
		GlobalStore.loading(true)
		const formVals = form.values()

		let req = {
			record_id: isNotEmpty(formVals._id) ? formVals._id : null,
			_type: "IPCEI.API.JSON.Proxy.MicroElectronics",
			version: isNotEmpty(version) ? version : "draft",
			status: isNotEmpty(version) && version == "final" ? "sent" : "inp",
			type_id: "IPCEI.Data.MicroElectronics",
			attached_files: dropzoneData,
			project_name: formVals.projectName,
			person_data: {
				first_name: formVals.name,
				last_name: formVals.surname,
				email: isNotEmpty(formVals.mail) ? formVals.mail : null,
				phone: isNotEmpty(formVals.phone) ? formVals.phone : null,
				_type: "IPCEI.API.JSON.Proxy.Person"
			},
			readiness_period:
				isNotEmpty(formVals.plannedProjectStartYear) && isNotEmpty(formVals.plannedProjectStartMonth)
					? `${formVals.plannedProjectStartYear}${formVals.plannedProjectStartMonth}`
					: null,
			duration_in_months: isNotEmpty(formVals.durationInMonth) ? formVals.durationInMonth : null,
			planned_investment_scale: isNotEmpty(formVals.plannedInvestment) ? formVals.plannedInvestment : null,
			annual_opex: isNotEmpty(formVals.plannedOpex) ? formVals.plannedOpex : null,
			project_summary: formVals.projectSummary,
			detailed_description: formVals.specificProjectDescription,
			added_value_description: formVals.projectValueForHydrogenEcosystem,
			quantitative_goal: isNotEmpty(formVals.plannedGoals) ? formVals.plannedGoals : null,
			offerings_to_partner: formVals.projectEnablesPartnersTo,
			looking_for_partners: formVals.projectLooksForPartnersFrom,
			without_gov_reason: formVals.projectNeedsStatesHelp,
			assumption: formVals.projectAssumptions,
			region: isNotEmpty(formVals.applicantRegion) ? formVals.applicantRegion : null,
			city: isNotEmpty(formVals.applicantCity) ? formVals.applicantCity : null,
			country: isNotEmpty(formVals.applicantCountry) ? formVals.applicantCountry : null,
			project_acronym: formVals.projectAcronym,
			class_of_publ_lvl_of_prj: isSafe(formVals.classificationOfPublicity) ? formVals.classificationOfPublicity : "",
			comm_by_ms_catlg_mgr: isSafe(formVals.commentsByMs) ? formVals.commentsByMs : ""
		}

		if (isNotEmpty(formVals.person_id)) {
			req.person_data["person"] = {
				_id: formVals.person_id,
				_type: "Entity.Data.Person"
			}
		}

		let activities = []

		if (formVals.activityTypeRD) {
			const activityType = GlobalStore.CL["ME_activityType"].find((row) => row.code == "RD")
			activities.push(activityType._id)
		}
		if (formVals.activityTypeFID) {
			const activityType = GlobalStore.CL["ME_activityType"].find((row) => row.code == "FID")
			activities.push(activityType._id)
		}

		if (activities.length) {
			req["activity_types"] = activities
		}

		let sectors = []

		if (formVals.sectorAutomotive) {
			const sector = GlobalStore.CL["ME_sector"].find((row) => row.code == "Automotive")
			sectors.push(sector._id)
		}
		if (formVals.sectorHealth) {
			const sector = GlobalStore.CL["ME_sector"].find((row) => row.code == "Health")
			sectors.push(sector._id)
		}
		if (formVals.sectorIndustry) {
			const sector = GlobalStore.CL["ME_sector"].find((row) => row.code == "Industry")
			sectors.push(sector._id)
		}
		if (formVals.sectorAdvancedPackagingAndTesting) {
			const sector = GlobalStore.CL["ME_sector"].find((row) => row.code == "AdvPaT")
			sectors.push(sector._id)
		}
		if (formVals.sectorCompoundMaterials) {
			const sector = GlobalStore.CL["ME_sector"].find((row) => row.code == "CompoundMaterials")
			sectors.push(sector._id)
		}
		if (formVals.sectorTelecommunication) {
			const sector = GlobalStore.CL["ME_sector"].find((row) => row.code == "Telecommunication")
			sectors.push(sector._id)
		}
		if (formVals.sectorAeronautics) {
			const sector = GlobalStore.CL["ME_sector"].find((row) => row.code == "Aeronautics")
			sectors.push(sector._id)
		}
		if (formVals.sectorEnergyEfficiencyChips) {
			const sector = GlobalStore.CL["ME_sector"].find((row) => row.code == "EEChips")
			sectors.push(sector._id)
		}
		if (formVals.sectorSemiconductorManufacturing) {
			const sector = GlobalStore.CL["ME_sector"].find((row) => row.code == "SemicondManufact")
			sectors.push(sector._id)
		}
		if (formVals.sectorInternetOfThings) {
			const sector = GlobalStore.CL["ME_sector"].find((row) => row.code == "IOT")
			sectors.push(sector._id)
		}
		if (formVals.sectorCyberSecurity) {
			const sector = GlobalStore.CL["ME_sector"].find((row) => row.code == "CyberSecurity")
			sectors.push(sector._id)
		}
		if (formVals.sectorSensors) {
			const sector = GlobalStore.CL["ME_sector"].find((row) => row.code == "Sensors")
			sectors.push(sector._id)
		}
		if (formVals.sectorOther) {
			const sector = GlobalStore.CL["ME_sector"].find((row) => row.code == "Other")
			sectors.push(sector._id)
			req["other_sector_desc"] = formVals.sectorOtherDescription
		}

		if (sectors.length) {
			req["sectors"] = sectors
		}

		let beforeReadinessLevel = []

		GlobalStore.CL["beforeReadinessLevel"].forEach((level) => {
			if (formVals[`before${level.code}`]) {
				beforeReadinessLevel.push(level._id)
			}
		})

		if (beforeReadinessLevel.length) {
			req["before_readiness_levels"] = beforeReadinessLevel
		}

		let afterReadinessLevel = []

		GlobalStore.CL["afterReadinessLevel"].forEach((level) => {
			if (formVals[`after${level.code}`]) {
				afterReadinessLevel.push(level._id)
			}
		})

		if (afterReadinessLevel.length) {
			req["after_readiness_levels"] = afterReadinessLevel
		}

		//pridanie company
		req["company_data"] = {
			company: isNotEmpty(formVals.company_id) ? formVals.company_id : null,
			description: isNotEmpty(formVals.contactDescription) ? formVals.contactDescription : "",
			identifier: formVals.ico,
			name: formVals.companyName,
			address: {
				country: {
					_type: "Entity.CL.Country",
					_id: formVals.contactCountry
				},
				county: {
					_type: "Entity.CL.Address.County",
					_id: formVals.companyCounty
				},
				description: "",
				house_number: isNotEmpty(formVals.companyStreetNumber) ? formVals.companyStreetNumber : null,
				obj_city: {
					_type: "Entity.CL.Address.City",
					_id: formVals.companyCity
				},
				primary: true,
				region: {
					_type: "Entity.CL.Address.Region",
					_id: formVals.companyRegion
				},
				street_plain: formVals.companyStreet,
				type: {
					_type: "Entity.CL.AddressType",
					_id: "OA"
				},
				zip_plain: formVals.companyPSC
			}
		}

		api
			.saveRequest(req)
			.call()
			.then((response) => {
				GlobalStore.setNotificationMessage("Record saved successfully", "", "success")
				if (isSafe(callback)) {
					callback(response._id)
				}
			})
	}

	@action loadAttachemntTemplates() {
		api
			.loadAttachmentTemplates()
			.call()
			.then((response) => {
				if (isSafe(response)) {
					this.attachmentTemplates = response.sort((a, b) => a.item_order - b.item_order)
				}
			})
	}

	@action load(form, id) {
		GlobalStore.loading(true)
		api
			.loadRequestWithAtt(id)
			.call()
			.then((response) => {
				form.reset()
				const company = response.company_data
				const addresses = company.address

				let formObj = {
					_id: id,
					company_id: company.company,
					status: response.status._id,
					projectName: response.project_name,
					plannedProjectStart: response.readiness_period,
					durationInMonth: isSafe(response.duration_in_months) ? response.duration_in_months : "",
					plannedInvestment: response.planned_investment_scale,
					plannedOpex: response.annual_opex,
					projectSummary: response.project_summary,
					specificProjectDescription: response.detailed_description,
					projectValueForHydrogenEcosystem: response.added_value_description,
					plannedGoals: response.quantitative_goal,
					projectEnablesPartnersTo: response.offerings_to_partner,
					projectLooksForPartnersFrom: response.looking_for_partners,
					projectNeedsStatesHelp: response.without_gov_reason,
					projectAssumptions: response.assumption,
					ico: company.identifier,
					companyName: company.name,
					contactDescription: company.description,
					companyPSC: addresses.zip_plain,
					companyStreet: isSafe(addresses.street_plain) ? addresses.street_plain : "",
					companyStreetNumber: isSafe(addresses.house_number) ? addresses.house_number : "",
					contactCountry: isSafe(addresses.country) ? addresses.country : "",
					companyRegion: isSafe(addresses.region) ? addresses.region : "",
					companyCounty: isSafe(addresses.county) ? addresses.county : "",
					companyCity: isSafe(addresses.obj_city) ? addresses.obj_city : "",
					plannedProjectStartYear: isNotEmpty(response.readiness_period) ? response.readiness_period.slice(0, 4) : "",
					plannedProjectStartMonth: isNotEmpty(response.readiness_period) ? response.readiness_period.slice(-2) : "",
					applicantRegion: isSafe(response.region) ? response.region._id : "",
					applicantCity: isSafe(response.city) ? response.city._id : "",
					applicantCountry: isSafe(response.country) ? response.country._id : "",
					projectAcronym: response.project_acronym,
					person_id:
						isSafe(response.person_data) && isSafe(response.person_data.person) ? response.person_data.person : "",
					mail: isSafe(response.person_data) ? response.person_data.email : "",
					phone: isSafe(response.person_data) ? response.person_data.phone : "",
					name: isSafe(response.person_data) ? response.person_data.first_name : "",
					surname: isSafe(response.person_data) ? response.person_data.last_name : "",
					classificationOfPublicity: isSafe(response.class_of_publ_lvl_of_prj) ? response.class_of_publ_lvl_of_prj : "",
					commentsByMs: isSafe(response.comm_by_ms_catlg_mgr) ? response.comm_by_ms_catlg_mgr : ""
				}

				if (isSafe(response.activity_types) && response.activity_types.length) {
					response.activity_types.forEach((sector) => {
						if (sector.code == "RD") {
							formObj.activityTypeRD = true
						} else if (sector.code == "FID") {
							formObj.activityTypeFID = true
						}
					})
				}

				if (isSafe(response.sectors) && response.sectors.length) {
					response.sectors.forEach((sector) => {
						if (sector.code == "AdvPaT") {
							formObj.sectorAdvancedPackagingAndTesting = true
						} else if (sector.code == "Automotive") {
							formObj.sectorAutomotive = true
						} else if (sector.code == "Health") {
							formObj.sectorHealth = true
						} else if (sector.code == "Industry") {
							formObj.sectorIndustry = true
						} else if (sector.code == "CompoundMaterials") {
							formObj.sectorCompoundMaterials = true
						} else if (sector.code == "Telecommunication") {
							formObj.sectorTelecommunication = true
						} else if (sector.code == "Aeronautics") {
							formObj.sectorAeronautics = true
						} else if (sector.code == "EEChips") {
							formObj.sectorEnergyEfficiencyChips = true
						} else if (sector.code == "SemicondManufact") {
							formObj.sectorSemiconductorManufacturing = true
						} else if (sector.code == "IOT") {
							formObj.sectorInternetOfThings = true
						} else if (sector.code == "CyberSecurity") {
							formObj.sectorCyberSecurity = true
						} else if (sector.code == "Sensors") {
							formObj.sectorSensors = true
						} else if (sector.code == "Other") {
							formObj.sectorOther = true
							formObj.sectorOtherDescription = response.other_sector_desc
						}
					})
				}

				if (isSafe(response.attached_files) && response.attached_files.length) {
					response.attached_files.forEach((attachment) => {
						const blob = b64toBlob(attachment.content)
						let tmp = new Blob([blob], {
							type: attachment.content_type
						})
						tmp.name = attachment.file_name
						tmp._id = attachment.attachment_id
						tmp.databaseType = attachment.type

						if (tmp.databaseType == "annexsd") {
							this.dropedFiles0.push(tmp)
						}
					})
				}

				if (isSafe(response.before_readiness_levels) && response.before_readiness_levels.length) {
					response.before_readiness_levels.forEach((level) => {
						formObj[`before${level.code}`] = true
					})
				}

				if (isSafe(response.after_readiness_levels) && response.after_readiness_levels.length) {
					response.after_readiness_levels.forEach((level) => {
						formObj[`after${level.code}`] = true
					})
				}

				form.set(formObj)
				this.defaultFormValues = form.values()
				GlobalStore.loading(false)
			})
	}

	downloadSpiecificTemplate(uri, name) {
		api.downloadFile(uri, name)
	}

	validateDraft(form, callback) {
		const formVals = form.values()
		if (
			isEmpty(formVals.companyName) ||
			isEmpty(formVals.ico) ||
			isEmpty(formVals.ico) ||
			isEmpty(formVals.contactCountry) ||
			isEmpty(formVals.companyRegion) ||
			isEmpty(formVals.companyCounty) ||
			isEmpty(formVals.companyCity) ||
			isEmpty(formVals.companyPSC) ||
			isEmpty(formVals.name) ||
			isEmpty(formVals.surname) ||
			isEmpty(formVals.mail) ||
			isEmpty(formVals.phone)
		) {
			GlobalStore.setNotificationMessage(
				"Applicant identification data and Contact person data are required!",
				null,
				"error"
			)
		} else {
			callback()
		}
	}

	loadCodelistAndForm(form, id) {
		RegistersCacheStore.checkRegisters("/microelectronic")
		this.load(form, id)
	}
}

var singleton = new MicroelectronicFormStore()
export default singleton
