import {action} from "mobx"

import {printReport} from "../../global/helpers/actions"

import api from "../../global/actions/api"

import GlobalStore from "../../global/store/GlobalStore"

class MicroelectronicPrintStore {
	@action print(id) {
		GlobalStore.loading(true)

		api
			.loadPrintRequest(id)
			.call()
			.then((data) => {
				if (isSafe(data)) {
					const company = data.company_data
					const person = data.person_data
					// const orgunit = data.company.specific_rels.find((row) => row._type == "Entity.Data.OrgUnit")

					let printValues = {
						project_name: isSafe(data.project_name) ? data.project_name : "",
						company_description: isSafe(company.description) ? company.description : "",
						// project_summary: isSafe(data.project_summary) ? data.project_summary : "",
						ico: isSafe(company.identifier) ? company.identifier : "",
						full_name:
							isSafe(person.last_name) && isSafe(person.first_name) ? `${person.first_name} ${person.last_name}` : "",
						email: isSafe(person.email) ? person.email : "",
						phone: isSafe(person.phone) ? person.phone : "",
						company_name: company.name,
						readiness_date:
							isNotEmpty(data.readiness_period) && data.readiness_period.length == 6
								? `${data.readiness_period.slice(4, 6)}/${data.readiness_period.slice(0, 4)}`
								: "",
						duration_in_months: isSafe(data.duration_in_months) ? data.duration_in_months : "",
						planned_investment_scale: isSafe(data.planned_investment_scale) ? data.planned_investment_scale : "",
						annual_opex: isSafe(data.annual_opex) ? data.annual_opex : "",
						detailed_project_description: isSafe(data.detailed_description) ? data.detailed_description : "",
						added_value_description: isSafe(data.added_value_description) ? data.added_value_description : "",
						quantitative_goal: isSafe(data.quantitative_goal) ? data.quantitative_goal : "",
						offerings_to_partner: isSafe(data.offerings_to_partner) ? data.offerings_to_partner : "",
						looking_for_partners: isSafe(data.looking_for_partners) ? data.looking_for_partners : "",
						without_gov_reason: isSafe(data.without_gov_reason) ? data.without_gov_reason : "",
						assumption: isSafe(data.assumption) ? data.assumption : "",
						country: isSafe(data.country) && isSafe(data.country.name_ext) ? data.country.name_ext : "",
						region: isSafe(data.region_plain) ? data.region_plain : "",
						town: isSafe(data.city_plain) ? data.city_plain : "",
						classification_of_publicity: isSafe(data.class_of_publ_lvl_of_prj) ? data.class_of_publ_lvl_of_prj : "",
						comments_by_ms: isSafe(data.comm_by_ms_catlg_mgr) ? data.comm_by_ms_catlg_mgr : ""
					}

					if (isSafe(company.address)) {
						printValues["company_address"] = isSafe(company.address.full_address) ? company.address.full_address : ""
					}

					if (isSafe(data.sectors) && data.sectors.length) {
						let sect = ""

						data.sectors.forEach((x) => {
							if (x.code == "Other") {
								sect += `<p>Other (${data.other_sector_desc})</p>`
							} else {
								sect += `<p>${x.name}</p>`
							}
						})

						printValues["sectors"] = sect
					}

					if (isSafe(data.before_readiness_levels) && data.before_readiness_levels.length) {
						let beforeTRLs = "<div><b>At the start of project</b></div>"

						data.before_readiness_levels.forEach((x) => {
							beforeTRLs += `<p>${x.code_ext} - ${x.name_ext}</p>`
						})

						printValues["before_tech_readiness_level"] = beforeTRLs
					}

					if (isSafe(data.after_readiness_levels) && data.after_readiness_levels.length) {
						let afterTRLs = "<div><b>After completion of project</b></div>"

						data.after_readiness_levels.forEach((x) => {
							afterTRLs += `<p>${x.code_ext} - ${x.name_ext}</p>`
						})

						printValues["after_tech_readiness_level"] = afterTRLs
					}

					if (isSafe(data.activity_types) && data.activity_types) {
						let activities = ""

						data.activity_types.forEach((x) => {
							if (x.code == "oth") {
								activities += `<p>Other (${data.other_activity_type_desc})</p>`
							} else {
								activities += `<p>${x.name}</p>`
							}
						})

						printValues["activities"] = activities
					}

					if (isSafe(data.hydrogen_types) && data.hydrogen_types) {
						let hydrogens = ""

						data.hydrogen_types.forEach((x) => {
							if (x.code == "oth") {
								hydrogens += `<p>Other (${data.other_hydrogen_type_desc})</p>`
							} else {
								hydrogens += `<p>${x.name}</p>`
							}
						})

						printValues["hydrogen_types"] = hydrogens
					}

					if (isSafe(data.attached_files) && data.attached_files) {
						let attachments = ""

						data.attached_files.forEach((x) => {
							attachments += `<p>${x.file_name}</p>`
						})

						printValues["attached_files"] = attachments
					}

					printReport("microelectronic", printValues)
				}

				GlobalStore.loading(false)
			})
	}
}

var singleton = new MicroelectronicPrintStore()
export default singleton
