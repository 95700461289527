"use strict"

import React from "react"
import {observer} from "mobx-react"
import classnames from "classnames"

import {useTable, useSortBy, usePagination, useResizeColumns, useFlexLayout} from "react-table"

import {Grid, Box, Table, TableBody, TableCell, TableHead, TableRow, MenuItem, TextField} from "@material-ui/core"

import XsIconButton from "../xsButton/xsIconButton"

import {/*FormattedMessage,*/ injectIntl} from "react-intl"

import "./xsTable.less"

const RTable = ({columns, data, onRowClick}) => {
	const filterTypes = React.useMemo(
		() => ({
			text: (rows, id, filterValue) => {
				return rows.filter((row) => {
					const rowValue = row.values[id]
					return rowValue !== undefined
						? String(rowValue)
								.toLowerCase()
								.startsWith(String(filterValue).toLowerCase())
						: true
				})
			}
		}),
		[]
	)

	const defaultColumn = React.useMemo(
		() => ({
			minWidth: 50,
			width: 150
			// maxWidth: 400,
		}),
		[]
	)

	const {
		getTableProps,
		getTableBodyProps,
		// footerGroups,
		headerGroups,
		rows,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: {pageIndex, pageSize}
	} = useTable(
		{
			columns,
			data,
			defaultColumn,
			filterTypes,
			initialState: {pageIndex: 0}
		},
		useSortBy,
		usePagination,
		useResizeColumns,
		useFlexLayout
	)

	return (
		<React.Fragment>
			<Table {...getTableProps()} className="r-table" size="small">
				<TableHead>
					{headerGroups.map((headerGroup, idx) => (
						<TableRow key={idx} {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column, i) => {
								// const {isResizing, getResizerProps} = column
								return (
									<TableCell
										key={`${idx}_${i}`}
										{...column.getHeaderProps(column.getSortByToggleProps())}
										// className={`td resizer ${isResizing ? "isResizing" : ""}`}
									>
										{column.render("Header")}
										<span>
											{column.isSorted ? (
												column.isSortedDesc ? (
													<span>
														{" "}
														<i className="fal fa-long-arrow-down"></i>
													</span>
												) : (
													<span>
														{" "}
														<i className="fal fa-long-arrow-up"></i>
													</span>
												)
											) : (
												""
											)}
										</span>
										{/* <div {...getResizerProps()} className={`resizer ${isResizing ? "isResizing" : ""}`} /> */}
									</TableCell>
								)
							})}
						</TableRow>
					))}
				</TableHead>
				<TableBody {...getTableBodyProps()}>
					{page.map((row, idx) => {
						const isRowClick = isSafe(onRowClick) && typeof onRowClick === "function" ? true : false
						prepareRow(row)
						return (
							<TableRow
								key={idx}
								{...row.getRowProps()}
								onClick={() => (isRowClick ? onRowClick(row.original) : null)}
								className={classnames({pointer: isRowClick})}
							>
								{row.cells.map((cell, i) => {
									return (
										<TableCell key={`${idx}_${i}`} {...cell.getCellProps()} className="td">
											{cell.render("Cell")}
										</TableCell>
									)
								})}
							</TableRow>
						)
					})}
				</TableBody>
			</Table>
			<Box pt={1}>
				<Grid container spacing={2} alignItems="center" justify="flex-end">
					{/* <Grid item>Počet záznamov na stranu:</Grid> */}
					<Grid item>Rows per page:</Grid>
					<Grid item>
						<TextField
							size="small"
							variant="outlined"
							select
							value={pageSize}
							onChange={(e) => setPageSize(Number(e.target.value))}
						>
							<MenuItem key={5} value={5}>
								5
							</MenuItem>
							<MenuItem key={10} value={10}>
								10
							</MenuItem>
							<MenuItem key={20} value={20}>
								20
							</MenuItem>
							<MenuItem key={50} value={50}>
								50
							</MenuItem>
						</TextField>
					</Grid>
					<Grid item>
						<strong>
							{pageIndex + 1} - {pageOptions.length} of {rows.length}
						</strong>
					</Grid>
					<Grid item>
						<XsIconButton
							size="large"
							variant="text"
							icon={<i className="far fa-chevron-double-left" />}
							onClick={() => gotoPage(0)}
							disabled={!canPreviousPage}
							color="default"
						/>
					</Grid>
					<Grid item>
						<XsIconButton
							size="large"
							variant="text"
							icon={<i className="far fa-chevron-left" />}
							onClick={() => previousPage()}
							disabled={!canPreviousPage}
							color="default"
						/>
					</Grid>
					<Grid item>
						<XsIconButton
							size="large"
							variant="text"
							icon={<i className="far fa-chevron-right" />}
							onClick={() => nextPage()}
							disabled={!canNextPage}
							color="default"
						/>
					</Grid>
					<Grid item>
						<XsIconButton
							size="large"
							variant="text"
							icon={<i className="far fa-chevron-double-right" />}
							onClick={() => gotoPage(pageCount - 1)}
							disabled={!canNextPage}
							color="default"
						/>
					</Grid>
					{/* <Grid item>| Go to page:</Grid>
				<Grid item>
					<TextField
						type="number"
						variant="outlined"
						defaultValue={pageIndex + 1}
						size="small"
						onChange={(e) => {
							const page = e.target.value ? Number(e.target.value) - 1 : 0
							gotoPage(page)
						}}
					/>
				</Grid> */}
				</Grid>
			</Box>
		</React.Fragment>
	)
}

function xsTable({columns, data, onRowClick}) {
	return <RTable columns={columns} data={data} onRowClick={(row) => onRowClick(row)} />
}

export default injectIntl(observer(xsTable))
