"use strict"

import React, {useState} from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"
import {injectIntl, FormattedMessage} from "react-intl"

import {Container, Grid, Paper, Typography, Link, Box} from "@material-ui/core"

import XsInput from "../../global/ui/xsInput/xsInput"
import XsButton from "../../global/ui/xsButton/xsButton"
import XsCheckbox from "../../global/ui/xsCheckbox/xsCheckbox"

import LoginStore from "./loginStore"
import RouterStore from "../../global/store/RouterStore"
import GlobalStore from "../../global/store/GlobalStore"

function Register() {
	const hooks = {
		onSubmit(form) {
			const {email, first_name, last_name} = form.values()

			if (isEmpty(email) || isEmpty(first_name) || isEmpty(last_name)) {
				GlobalStore.setNotificationMessage("Email, Name and Surname are required!", "", "warning")
				form.invalidate()
			}
		},
		onSuccess(form) {
			LoginStore.registration(form)
		},
		onError() {}
	}

	const [form] = useState(
		new MobxReactForm(
			{
				fields: {
					email: {label: <FormattedMessage id="Common.label.email" />, rules: "email|string"},
					first_name: {label: <FormattedMessage id="Common.label.name" />, rules: ""},
					last_name: {label: <FormattedMessage id="Common.label.surname" />, rules: ""},
					confirmation: {
						label: (
							<span>
								Consent to Personal Data Processing for the purpose of registration on the IPCEI portal <br />
								(Súhlas so spracovaním osobných údajov za účelom registrácie na portál IPCEI)
							</span>
						),
						type: "checkbox",
						value: false
					}
				}
			},
			{plugins: {dvr: dvr(validatorjs)}, hooks}
		)
	)

	return (
		<React.Fragment>
			<div className="main_header">
				<Container maxWidth="lg">
					<Grid container alignItems="center" justify="space-between" className="pt-2 pb-2">
						<Grid item>
							<img
								src="/public/images/logo_siea_vertical.png"
								className="pointer"
								onClick={() => RouterStore.push("/login")}
							/>
						</Grid>
						<Grid item className="header_gradient">
							<Typography variant="h3">Register (Sign in)</Typography>
						</Grid>
						<Grid item style={{width: 100}}></Grid>
					</Grid>
				</Container>
			</div>
			<Container maxWidth="lg" className="mt-4">
				<Grid container justify="center">
					<Grid item>
						<Grid container direction="column" justify="center">
							<Paper variant="elevation" elevation={2} style={{backgroundColor: "transparent"}}>
								<Box p={2}>
									<Grid item container direction="column" spacing={2}>
										<Grid item>
											<Typography variant="body2">
												If you want to register on the IPCEI portal, fill in the fields{" "}
												<span className="bold">Name</span>, <span className="bold">Surname</span> and your{" "}
												<span className="bold">Email address</span>. You will receive a registration email with a
												registration link. To complete the registration click the registration link from email you will
												receive.
												{/* Ak sa chcete zaregistrovať na IPCEI portál, vyplňte položky Meno, Priezvisko a vašu Emailovú adresu, na
							ktorú vám bude doručený registračný email s registračnou linkou, na ktorú je potrebné kliknúť a dokončiť
							registráciu. */}
											</Typography>
										</Grid>
										<Grid item container spacing={1}>
											<Grid item xs={12} md={6}>
												<XsInput field={form.$("first_name")} autoFocus={true} submitAction={(e) => form.onSubmit(e)} />
											</Grid>
											<Grid item xs={12} md={6}>
												<XsInput field={form.$("last_name")} submitAction={(e) => form.onSubmit(e)} />
											</Grid>
										</Grid>
										<Grid item>
											<XsInput field={form.$("email")} submitAction={(e) => form.onSubmit(e)} />
										</Grid>
										<Grid item>
											<XsCheckbox field={form.$("confirmation")} />
											<Typography variant="body2" className="greyDefault pt-1">
												I acknowledge that the Slovak Innovation and Energy Agency processes following personal data:
												title, name, surname, e-mail address and telephone number of the contact person for the purpose
												of providing information about IPCEI projects via e-mail messages. The processing of personal
												data is necessary in accordance with the provisions of § 13 par. 1 letter e) of Act no. 18/2018
												Coll. on the protection of personal data and on the amendment of certain laws and at the same
												time in accordance with the provisions of Article 6 para. 1. letter e) Regulation (EU) 2016/679
												of the European Parliament and of the Council on the protection of individuals with regard to
												the processing of personal data and on the free movement of such data. Detailed information on
												the processing of data in SIEA information systems is available at&nbsp;
												<Link
													onClick={() => window.open("https://www.siea.sk/o-siea/ochrana-osobnych-udajov/", "_blank")}
												>
													https://www.siea.sk/o-siea/ochrana-osobnych-udajov/
												</Link>
												.
											</Typography>
											<Typography variant="body2" className="greyDefault pt-1">
												(Podľa ustanovenia § 13 ods. 1 písm. a) zákona č. 18/2018 Z. z. o ochrane osobných údajov a o
												zmene a doplnení niektorých zákonov a zároveň v súlade s ustanoveniami Nariadenia Európskeho
												parlamentu a Rady (EÚ) 2016/679 o ochrane fyzických osôb pri spracúvaní osobných údajov a o
												voľnom pohybe takýchto údajov súhlasím, aby Slovenská inovačná a energetická agentúra spracovala
												automatizovaným a neautomatizovaným spôsobom moje osobné údaje, konkrétne meno, priezvisko,
												e-mailovú adresu a telefónne číslo za účelom registrácie na IPCEI portál. Tento súhlas udeľujem
												do jeho odvolania. Odvolanie súhlasu je možné kedykoľvek realizovať zaslaním e-mailovej správy
												na adresu gdpr@siea.gov.sk. Podrobné informácie o spracúvaní údajov v informačných systémoch
												SIEA sú k dispozícii na webovej stránke&nbsp;
												<Link
													onClick={() => window.open("https://www.siea.sk/o-siea/ochrana-osobnych-udajov/", "_blank")}
												>
													https://www.siea.sk/o-siea/ochrana-osobnych-udajov/
												</Link>
												)
											</Typography>
										</Grid>
										<Grid item>
											<XsButton
												disabled={!form.$("confirmation").value || GlobalStore.isRegistrationClose()}
												text={<FormattedMessage id="Common.label.register" />}
												onClick={form.onSubmit}
											/>
										</Grid>
										<Grid item>
											<Link variant="body2" className="pointer" onClick={() => RouterStore.push("/login")}>
												<FormattedMessage id="Common.label.sigin" />
											</Link>
										</Grid>
									</Grid>
								</Box>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</React.Fragment>
	)
}

export default injectIntl(observer(Register))
