import React, { useEffect } from "react"
import { observer } from "mobx-react"
import Grid from "@material-ui/core/Grid"
import MomentUtils from "@date-io/moment"
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"
// import { FormattedMessage } from "react-intl"

import "./xsDateTimePicker.less"

function XsDateTimePicker(props) {
	// V rules fieldu musi byt date inak ulozi aj nevalidny date
	useEffect(() => {
		const rules = props.field.get("rules")
		if (isSafe(rules) && !rules.includes("date")) {
			props.field.set("rules", rules + (rules.length > 0 ? "|date" : "date"))
		}
	}, [])

	const handleDateChange = (date, value) => {
		props.field.set("value", date)
		if (isSafe(props.onChange) && typeof props.onChange === "function") {
			props.onChange(date, value)
		}
	}

	return (
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<Grid container justify="space-around">
				<KeyboardDatePicker
					autoOk
					fullWidth
					inputVariant="outlined"
					variant="inline"
					size="small"
					orientation="landscape"
					format="DD.MM.yyyy"
					// margin="normal"
					label={props.field.get("label")}
					value={isEmpty(props.field.get("value")) ? null : props.field.get("value")}
					onChange={handleDateChange}
					InputProps={{ className: "datepickerInput" }}
					InputAdornmentProps={{ position: "start" }}
					KeyboardButtonProps={{
						"aria-label": "change date"
					}}
					// invalidDateMessage={<div><FormattedMessage id="Common.invalidDate" /></div>}
					// error={true}
					helperText={null}
				/>
			</Grid>
		</MuiPickersUtilsProvider>
	)
}
export default observer(XsDateTimePicker)
