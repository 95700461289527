"use strict"

import React, {useState} from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"
import {injectIntl, FormattedMessage} from "react-intl"

import {Grid, Paper, Typography, Link, Box} from "@material-ui/core"

import XsInput from "../../global/ui/xsInput/xsInput"
import XsButton from "../../global/ui/xsButton/xsButton"

import LoginStore from "./loginStore"
import GlobalStore from "../../global/store/GlobalStore"

function ForgotPassword() {
	const hooks = {
		onSubmit() {
			const {email} = form.values()

			if (isEmpty(email)) {
				GlobalStore.setNotificationMessage("Email is required!", "", "warning")
				form.invalidate()
			}
		},
		onSuccess(form) {
			LoginStore.resetPassword(form)
		},
		onError() {}
	}

	const [form] = useState(
		new MobxReactForm(
			{
				fields: {
					email: {label: "Your email", rules: "email|string"}
				}
			},
			{plugins: {dvr: dvr(validatorjs)}, hooks}
		)
	)

	return (
		<Paper variant="elevation" elevation={2} style={{backgroundColor: "transparent"}}>
			<Box p={2}>
				<Grid item container direction="column" spacing={2}>
					<Grid item>
						<XsInput field={form.$("email")} autoFocus={true} submitAction={(e) => form.onSubmit(e)} />
					</Grid>
					<Grid item>
						<XsButton text="Reset password" onClick={form.onSubmit} />
					</Grid>
					<Grid item container direction="column" spacing={1}>
						<Grid item>
							{/* <Typography variant="subtitle2">Ako na to:</Typography> */}
							<Typography variant="subtitle2">How to reset your password</Typography>
						</Grid>
						<Grid item>
							<Typography variant="body2">
								1. To reset your password, you must enter the email address you chose during your registration.
								{/* Pre obnovenie prihlasovacích údajov je nutné zadať e-mailovú adresu, ktorú ste zvolili pri svojej
								registrácii. */}
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant="body2">2. Your e-mail is used as your login name.</Typography>
							{/* Váš e-mail slúži zároveň ako prihlasovacie meno. */}
						</Grid>
						<Grid item>
							<Typography variant="body2">
								3. We will send you reset password link to your email.
								{/* Na tento e-mail vám pošleme odkaz, kde si budete môcť zvoliť vaše nové heslo. */}
							</Typography>
						</Grid>
					</Grid>
					<Grid item>
						<Link variant="body2" className="pointer" onClick={() => (LoginStore.screen = LoginStore.screens.LOGIN)}>
							<FormattedMessage id="Common.label.sigin" />
						</Link>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	)
}

export default injectIntl(observer(ForgotPassword))
