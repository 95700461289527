"use strict"

import React, {useEffect, useState} from "react"
import {observer} from "mobx-react"
import moment from "moment"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"

import {FormattedMessage, injectIntl} from "react-intl"
import {Container, Box, Grid, Typography} from "@material-ui/core"

import XsIconButton from "../../global/ui/xsButton/xsIconButton"
import XsTable from "../../global/ui/xsTable/xsTable"
import XsInput from "../../global/ui/xsInput/xsInput"
import XsSearchSelect from "../../global/ui/xsSearchSelect/xsSearchSelect"
import XsDateTimePicker from "../../global/ui/xsDateTimePicker/xsDateTimePicker"

import RequestStore from "./requestStore"
import RouterStore from "../../global/store/RouterStore"

const columns = [
	{
		Header: <FormattedMessage id="Common.label.createDate" />,
		width: 150,
		accessor: "created_at",
		sortType: (rowA, rowB) => {
			const created_at_A = rowA.original.created_at
			const created_at_B = rowB.original.created_at
			if (created_at_A > created_at_B) return -1
			if (created_at_B > created_at_A) return 1
			return 0
		},
		Cell: (props) => <div>{isNotEmpty(props.value) ? moment(props.value).format("DD.MM.YYYY HH:mm") : ""}</div>
	},
	{
		Header: <FormattedMessage id="Common.label.sentDate" />,
		width: 150,
		accessor: "sent_date_time",
		sortType: (rowA, rowB) => {
			const send_date_time_A = isNotEmpty(rowA.original.sent_date_time) ? rowA.original.sent_date_time : ""
			const send_date_time_B = isNotEmpty(rowB.original.sent_date_time) ? rowB.original.sent_date_time : ""
			if (send_date_time_A > send_date_time_B) return -1
			if (send_date_time_B > send_date_time_A) return 1
			return 0
		},
		Cell: (props) => <div>{isNotEmpty(props.value) ? moment(props.value).format("DD.MM.YYYY HH:mm") : ""}</div>
	},
	{
		Header: <FormattedMessage id="Common.label.state" />,
		width: 150,
		accessor: "status.name"
	},
	{
		Header: <FormattedMessage id="Common.label.companyName" />,
		width: 200,
		accessor: "company_data.name",
		sortType: "basic"
	},
	{
		Header: <FormattedMessage id="Common.label.ico" />,
		width: 125,
		accessor: "company_data.identifier",
		sortType: "basic"
	},
	{
		Header: <FormattedMessage id="Common.label.projectName" />,
		width: 200,
		accessor: "project_name",
		sortType: "basic",
		Cell: (props) => <div>{isSafe(props.value) ? props.value.shortTo(100) : ""}</div>
	},
	{
		Header: <FormattedMessage id="Common.label.amount" />,
		width: 150,
		accessor: "planned_investment_scale",
		sortType: "basic"
	}
]

function Requests() {
	const hooks = {
		onSubmit() {},
		onSuccess(form) {
			RequestStore.getRequests(form.values())
		},
		onError() {}
	}

	const [form] = useState(
		new MobxReactForm(
			{
				fields: {
					identifier: {label: <FormattedMessage id="Common.label.ico" />},
					company_name: {label: <FormattedMessage id="Common.label.companyName" />},
					from: {label: <FormattedMessage id="Common.label.sendFrom" />},
					to: {label: <FormattedMessage id="Common.label.sendTo" />},
					type_id: {label: <FormattedMessage id="Common.label.type" />}
				}
			},
			{plugins: {dvr: dvr(validatorjs)}, hooks}
		)
	)

	useEffect(() => {
		RequestStore.getRequests(form.values())
	}, [])

	const onRowClick = (row) => {
		logger("row", row)
		if (row.name == "IPCEI.Data.MicroElectronics") {
			RouterStore.push(`/microelectronic?id=${row.record_id}`)
		} else {
			RouterStore.push(`/request?id=${row.record_id}`)
		}
	}

	return (
		<Container maxWidth="lg">
			<Grid container direction="column">
				<Grid item>
					<Box pt={2} pb={2}>
						<Typography variant="h4" className="header_gradient">
							List of your IPCEI Registration forms (applications)
						</Typography>
					</Box>
				</Grid>
				<Grid item>
					<Box pb={2}>
						<Typography variant="body2">
							Click a row in the list to edit the IPCEI Registration form items. An IPCEI Registration form detail will
							open in which you can make changes and then save them.
						</Typography>
					</Box>
					{/* Ak chcete upraviť položky žiadosti, kliknite na riadok v zozname. Otvorí sa vám detail žiadosti, v ktorom
					môžete vykonať zmeny a tieto následne uložiť. */}
				</Grid>
			</Grid>
			<Box pt={2}>
				<Grid container>
					<Grid item container spacing={1}>
						<Grid item xs={12} md={3}>
							<XsInput field={form.$("company_name")} />
						</Grid>
						<Grid item xs={12} md={2}>
							<XsInput field={form.$("identifier")} />
						</Grid>
						<Grid item xs={12} md={2}>
							<XsDateTimePicker field={form.$("from")} />
						</Grid>
						<Grid item xs={12} md={2}>
							<XsDateTimePicker field={form.$("to")} />
						</Grid>
						<Grid item xs={12} md={2}>
							<XsSearchSelect
								field={form.$("type_id")}
								items={[
									{
										_id: "IPCEI.Data.MicroElectronics",
										code: "micro",
										code_ext: "micro",
										name: "Microelectronics",
										name_ext: "Microelectronics"
									},
									{_id: "IPCEI.Data.Request", code: "H2", code_ext: "H2", name: "H2", name_ext: "H2"}
								]}
							/>
						</Grid>
						<Grid item xs={12} md={1}>
							<XsIconButton icon={<i className="fal fa-search fa-btn"></i>} onClick={form.onSubmit} />
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Box pt={1}>
							<XsTable columns={columns} data={RequestStore.data} onRowClick={(row) => onRowClick(row)} />
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Container>
	)
}

export default injectIntl(observer(Requests))
