import {observable, action} from "mobx"
import {load} from "recaptcha-v3"

import GlobalStore from "../../global/store/GlobalStore"
import RouterStore from "../../global/store/RouterStore"
import config from "../../global/config/settings"

import {login} from "../../global/helpers/actions"

import api from "../../global/actions/api"

class LoginStore {
	@observable screen = this.screens.LOGIN

	token = null
	@observable email = ""

	screens = {
		LOGIN: "login",
		REGISTER: "register",
		FORGOT: "forgot"
	}

	@action registration(form) {
		const req = form.values()

		GlobalStore.loading(true, "Registration in progress...")

		load(config.SITE_KEY).then((recaptcha) => {
			recaptcha.execute("client_register").then((recaptchaToken) => {
				api
					.registration(req, recaptchaToken)
					.call()
					.then((res) => {
						logger("registration res: ", res)
						if (isSafe(res)) {
							form.reset()
							this.screen = this.screens.LOGIN
							GlobalStore.setNotificationMessage("Registration successful", "", "success")
						}
						GlobalStore.loading(false)
					})
			})
		})
	}

	@action registrationFinish(form) {
		const {password} = form.values()

		const req = {
			password: password,
			as_login: true
		}

		GlobalStore.loading(true)

		load(config.SITE_KEY).then((recaptcha) => {
			recaptcha.execute("client_action_consume").then((recaptchaToken) => {
				api
					.registrationFinish(req, this.token, recaptchaToken)
					.call()
					.then((res) => {
						if (isSafe(res) && isNotEmpty(res.login)) {
							login(res.login, password)
						}
					})
			})
		})
	}

	@action resetPassword(form) {
		const req = form.values()

		GlobalStore.loading(true, "Reset password...")

		load(config.SITE_KEY).then((recaptcha) => {
			recaptcha.execute("client_reset_password").then((recaptchaToken) => {
				api
					.resetPassword(req, recaptchaToken)
					.call()
					.then(() => {
						form.reset()
						this.screen = this.screens.LOGIN
						GlobalStore.setNotificationMessage("Request for password reset sent", "", "success")
						GlobalStore.loading(false)
					})
			})
		})
	}

	@action resetPasswordFinish(form) {
		const {password} = form.values()

		const req = {
			password: password,
			as_login: true
		}

		GlobalStore.loading(true)

		load(config.SITE_KEY).then((recaptcha) => {
			recaptcha.execute("client_action_consume").then((recaptchaToken) => {
				api
					.registrationFinish(req, this.token, recaptchaToken)
					.call()
					.then((res) => {
						if (isSafe(res)) {
							GlobalStore.setNotificationMessage("Password reset successful", "", "success")
							GlobalStore.loading(false)
							RouterStore.push("/login")
						}
					})
			})
		})
	}
}

var singleton = new LoginStore()
export default singleton
