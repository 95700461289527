"use strict"

import {observable, action} from "mobx"
import moment from "moment"

import api from "../../global/actions/api"

import GlobalStore from "../../global/store/GlobalStore"

class RequestStore {
	@observable data = []

	@action getRequests(filterValues) {
		GlobalStore.loading(true, "Loading applications...")

		let req = {filters: []}

		if (isNotEmpty(filterValues.identifier)) {
			req.filters.push({
				associated_column: "identifier",
				values: [
					{
						id_value: filterValues.identifier
					}
				]
			})
		}
		if (isNotEmpty(filterValues.company_name)) {
			req.filters.push({
				associated_column: "company_name",
				values: [
					{
						id_value: filterValues.company_name
					}
				]
			})
		}

		if (isNotEmpty(filterValues.from)) {
			req["time_from"] = moment(filterValues.from).startOf("day")
		}

		if (isNotEmpty(filterValues.to)) {
			req["time_to"] = moment(filterValues.to).endOf("day")
		}

		if (isNotEmpty(filterValues.type_id)) {
			req.filters.push({
				associated_column: "type_id",
				values: [
					{
						id_value: filterValues.type_id
					}
				]
			})
		}

		api
			.getRequests(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res.rows)) {
					this.data = res.rows
				} else {
					this.data = []
				}

				GlobalStore.loading(false)
			})
	}
}

var singleton = new RequestStore()
export default singleton
