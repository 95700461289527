export const codeListsConfig = [
	// {
	// 	uri: "/cl/SIEA.CL.Data.Object.Version",
	// 	name: "formVersions"
	// },
	{
		uri: "/cl/IPCEI.CL.Sector",
		name: "sectors"
	},
	{
		uri: "/cl/IPCEI.CL.HydrogenType",
		name: "hydrogenType"
	},
	{
		uri: "/cl/Entity.CL.Country",
		name: "countries"
	},
	{
		uri: "/cl/Entity.CL.Address.Region",
		name: "addressRegion"
	},
	{
		uri: "/entity/cl/county",
		name: "addressCounty"
	},
	{
		uri: "/cl/IPCEI.CL.ActivityType",
		name: "activityType"
	},
	{
		uri: "/cl/IPCEI.CL.BeforeReadinessLevel",
		name: "beforeReadinessLevel"
	},
	{
		uri: "/cl/IPCEI.CL.AfterReadinessLevel",
		name: "afterReadinessLevel"
	},
	{
		uri: "/cl/IPCEI.CL.MESector",
		name: "ME_sector"
	},
	{
		uri: "/cl/IPCEI.CL.MEActivityType",
		name: "ME_activityType"
	}
]
