import React from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"

import {Box, Grid, Container} from "@material-ui/core"
import XsButton from "../xsButton/xsButton"
import XsConfirmationDialog from "../xsDialog/xsConfirmationDialog"

import RequestFormStore from "../../../modules/RequestForm/RequestFormStore"
import MicroelectronicFormStore from "../../../modules/microelectronic/microelectronicFormStore"
import GlobalStore from "../../store/GlobalStore"
import RouterStore from "../../store/RouterStore"

import {logout} from "../../helpers/actions"

function XsHeader() {
	return (
		<div className="main_header">
			<Container maxWidth="lg">
				<Grid container alignItems="center" className="pt-2 pb-2">
					<Grid item container style={{width: 200}}>
						<Grid item>
							<Box
								mr={6}
								onClick={() => {
									const pathname = RouterStore.location.pathname

									if (pathname == "/request" || pathname == "/microelectronic") {
										delayedCallback(300, () => {
											if (pathname == "/request" && RequestFormStore.isSameFormValues()) {
												RequestFormStore.formRef.reset()
												RouterStore.push("/welcome")
											} else if (pathname == "/microelectronic" && MicroelectronicFormStore.isSameFormValues()) {
												MicroelectronicFormStore.formRef.reset()
												RouterStore.push("/welcome")
											} else {
												GlobalStore.openConfirmationDialog("xsCloseFormOverImage")
											}
										})
									} else {
										RouterStore.push("/welcome")
									}
								}}
								className="pointer"
							>
								<img src="/public/images/logo_siea_vertical.png" />
							</Box>
						</Grid>
					</Grid>
					<Grid item container xs justify="space-between" alignItems="center">
						<Grid item style={RouterStore.location.pathname == "/requests" ? {textDecoration: "underline"} : {}}>
							<XsButton
								icon={<i className="fas fa-file-signature fa-2x"></i>}
								variant="text"
								text="IPCEI Registration forms (applications)" //{<FormattedMessage id="Common.label.requests" />}
								onClick={() => {
									const pathname = RouterStore.location.pathname

									if (pathname == "/request" || pathname == "/microelectronic") {
										delayedCallback(300, () => {
											if (pathname == "/request" && RequestFormStore.isSameFormValues()) {
												RequestFormStore.formRef.reset()
												RouterStore.push("/requests")
											} else if (pathname == "/microelectronic" && MicroelectronicFormStore.isSameFormValues()) {
												MicroelectronicFormStore.formRef.reset()
												RouterStore.push("/requests")
											} else {
												GlobalStore.openConfirmationDialog("xsCloseFormOverRequests")
											}
										})
									} else {
										RouterStore.push("/requests")
									}
								}}
							/>
						</Grid>
						<Grid item>
							<XsButton
								icon={<i className="fas fa-sign-out-alt fa-2x"></i>}
								variant="text"
								text="Log out"
								onClick={() => {
									const pathname = RouterStore.location.pathname

									if (pathname == "/request" || pathname == "/microelectronic") {
										delayedCallback(300, () => {
											if (pathname == "/request" && RequestFormStore.isSameFormValues()) {
												logout()
											} else if (pathname == "/microelectronic" && MicroelectronicFormStore.isSameFormValues()) {
												logout()
											} else {
												GlobalStore.openConfirmationDialog("xsCloseFormOverLogout")
											}
										})
									} else {
										logout()
									}
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Container>
			<XsConfirmationDialog
				name="xsCloseFormOverImage"
				text="Are you sure you want to leave and discard recent changes?"
				confirmBtn={{
					icon: <i className="fal fa-check" />,
					text: "Common.btn.yes"
				}}
				cancelBtn={{
					icon: <i className="fal fa-times" />,
					text: "Common.btn.no"
				}}
				onConfirmation={() => {
					if (RouterStore.location.pathname == "/request") {
						RequestFormStore.formRef.reset()
					} else if (RouterStore.location.pathname == "/microelectronic") {
						MicroelectronicFormStore.formRef.reset()
					}

					RouterStore.push("/welcome")
				}}
			/>
			<XsConfirmationDialog
				name="xsCloseFormOverRequests"
				text="Are you sure you want to leave and discard recent changes?"
				confirmBtn={{
					icon: <i className="fal fa-check" />,
					text: "Common.btn.yes"
				}}
				cancelBtn={{
					icon: <i className="fal fa-times" />,
					text: "Common.btn.no"
				}}
				onConfirmation={() => {
					if (RouterStore.location.pathname == "/request") {
						RequestFormStore.formRef.reset()
					} else if (RouterStore.location.pathname == "/microelectronic") {
						MicroelectronicFormStore.formRef.reset()
					}

					RouterStore.push("/requests")
				}}
			/>
			<XsConfirmationDialog
				name="xsCloseFormOverLogout"
				text="Are you sure you want to leave and discard recent changes?"
				confirmBtn={{
					icon: <i className="fal fa-check" />,
					text: "Common.btn.yes"
				}}
				cancelBtn={{
					icon: <i className="fal fa-times" />,
					text: "Common.btn.no"
				}}
				onConfirmation={() => {
					logout()
				}}
			/>
		</div>
	)
}

export default injectIntl(observer(XsHeader))
